/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/header');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/search-modal');
require('./main/breadcrumbs');
require('./main/facetwp-filters');

import vtxDomReady from './common/helpers';
const { __, _x, sprintf, _n } = wp.i18n;

vtxDomReady(() => {
  // Examples of using gettext in JS
  console.log(__('Hello', 'vtx'), _x('hello', 'test', 'vtx'));
  /*translators: this is a test*/
  console.log(sprintf(_n('%d hello', '%d hellos', 1, 'vtx'), 1));

  /*translators: this is a test*/
  console.log(sprintf(_n('%d hello', '%d hellos', 4, 'vtx'), 4));
});
