//import TomSelect from 'tom-select';
// Button reset checkboxes filter (no filter selected) toggle class for button style
var allCheckboxBtn = document.querySelector('.btn-all-blog-posts');
var checkboxesList = document.querySelector('.facetwp-facet-categories');
function checkboxAllPosts() {
  var checkedBoxes = document.querySelectorAll(
    '.facetwp-checkbox.checked'
  ).length;

  if (checkedBoxes > 0) {
    allCheckboxBtn.classList.remove('btn-all-blog-posts__active');
  } else {
    allCheckboxBtn.classList.add('btn-all-blog-posts__active');
  }
}

document.addEventListener('facetwp-loaded', function () {
  if (null !== allCheckboxBtn && null !== checkboxesList) {
    checkboxAllPosts();
    checkboxesList.insertBefore(allCheckboxBtn, checkboxesList.children[0]);
  }
});
